import { compositionFunction as CallToActionComposition } from 'src/components/Common/@electron/CallToAction/composition';
import Composition from 'src/lib/HOC/Composition';
import { Data } from './data';
import IconList from './index';

const { compositionFunction, component } = Composition(IconList)<typeof Data & { rendering?: any }>(
  ({ fields, rendering }) => {
    const CTA = CallToActionComposition({ fields, rendering });
    const items = fields?.items?.map(item => ({
      image: item.fields?.['Item Icon'],
      headline: item.fields?.Headline,
      subhead: item.fields?.Subhead,
    }));
    const variant = fields?.Variant?.name?.toLowerCase();

    return { ...CTA, items, variant };
  }
);

export { compositionFunction, component as default };
