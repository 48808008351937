import { Text } from '@sitecore-jss/sitecore-jss-react';
import CallToAction from 'src/components/Common/@electron/CallToAction';
import LazyImage from 'src/components/Common/LazyImage';
import { IconListProps } from './types';

const IconListComponent = ({ items, variant }: IconListProps) => {
  const numberOfItems = items?.length || 0;
  const isSixToEightItems = numberOfItems > 5 && numberOfItems < 9;
  const isFourItems = numberOfItems === 4;
  const isFlat = variant === 'flat';

  const listClasses = isFlat
    ? 'container-sm md:container-xl flex-col md:flex-row mx-auto gap-x-32'
    : ' flex-wrap justify-center -m-12 sm:-m-16';

  return (
    <div className="container-4xl">
      <ul className={`flex ${listClasses}`}>
        {items?.map(({ image, headline, subhead }, index) => {
          const isFirstItem = index === 0;
          const bulletedOverviewStyles = `p-12 sm:p-16 w-1/2 sm:w-1/3 lg:w-1/5 ${
            isFourItems && isFirstItem ? 'sm:ml-px lg:ml-0' : ''
          } ${isSixToEightItems && isFirstItem ? 'lg:ml-px' : ''}`;
          return (
            <li className={`${isFlat ? '' : bulletedOverviewStyles}`} key={index}>
              <div
                className={
                  isFlat
                    ? 'flex flex-row gap-x-16 mb-24 font-bold items-center'
                    : 'relative text-center'
                }
              >
                <LazyImage
                  {...image}
                  alt=""
                  aria-hidden="true"
                  className={
                    isFlat
                      ? 'icon-24 sm:icon-40 fill-current text-teal-darker'
                      : 'fill-current icon-48 mx-auto mb-12 text-blue'
                  }
                />
                {headline?.value && (
                  <Text className="font-bold" field={headline} tag={subhead?.value ? 'h3' : 'p'} />
                )}
                {subhead?.value && <Text className="line-clamp-4" field={subhead} tag="p" />}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const IconList = ({ items, variant = '', ...rest }: IconListProps) => (
  <CallToAction {...rest}>
    <IconListComponent variant={variant} items={items}></IconListComponent>
  </CallToAction>
);

export default IconList;
